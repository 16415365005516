import {Responsive, ResponsiveSection} from '../../../styles.interface';
// import config from '@/product/config.json';

const PREMIUM_NO5: ResponsiveSection = {
  PremiumSectionStyled: {
    xs: {}
  },
  LinkStyled: {
    xs: {
      'font-weight': '600'
    }
  }
}

const Sections: Responsive = {
  PREMIUM_NO5,
};

export default Sections;
